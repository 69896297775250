import React from 'react';

import AuthenticatedLayout from "../components/layout/AuthenticatedLayout";

import {
    Divider as MuiDivider, Grid,
    styled
} from "@mui/material";
import { spacing } from "@mui/system";
import Costs from "../components/view/overviews/dashboard/Costs";

import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import createTheme from "../styles/theme";
import {THEMES} from '../styles/theme/constants';

const Divider = styled(MuiDivider)(spacing);

export default function CostsPage() {
    return (
        <MuiThemeProvider theme={createTheme(THEMES.DARK)}>
            <AuthenticatedLayout title={'Costs'}
                                 breadcrumbs={[]}
                                 size="xl">
                <Grid container spacing={2}>
                    <Divider />
                    <Costs/>
                </Grid>
            </AuthenticatedLayout>
        </MuiThemeProvider>
    )
}
